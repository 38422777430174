import { BaseFormModel, FormModel } from './base-form.model';

@FormModel( {
  location: { default: '' },
  groupkind: { default: '' },
  date: { default: null, validate: v => v instanceof Date },
  groupname: { default: '', required: false },
  personcount: { default: 10, validate: v => typeof v === 'number' && v >= 10 },
  lastname: { default: '' },
  firstname: { default: '' },
  street: { default: '', required: false },
  zip: { default: '', required: false },
  city: { default: '', required: false },
  phone: { default: '' },
  email: { default: '' },
  comment: { default: '', required: false }
} )
export class GroupVisitFormModel extends BaseFormModel { }
