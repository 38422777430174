import { ConstructorFn } from './descriptors';
import { injector } from 'angular';

// helper to get DI tokens
const $injector = injector();

export interface BaseInjectable extends ConstructorFn<any> {
  '[[instance]]': any;
  factory: <T>() => T;
}

export const BaseInjectable: BaseInjectable = ( () => { } ) as any as BaseInjectable;

interface InjectableDescriptorFn {
  (): ( <T extends BaseInjectable>( constrFn: T ) => typeof T );
}

export const Injectable: InjectableDescriptorFn = () => {
  return <T extends BaseInjectable>( constrFn: T ) => {
    // get dependencies to inject into service
    const diArgs = $injector.annotate( constrFn );

    // add static method factory()
    Object.defineProperty(
      constrFn,
      'factory',
      {
        configurable: false,
        enumerable: false,
        value: [
          ...diArgs,
          ( ...diValues: Array<any> ) => {
            if ( Object.getOwnPropertyDescriptor( constrFn, '[[instance]]' ) ) {
              // console.log( 'singleton instance found' );
              return constrFn[ '[[instance]]' ];
            }
            // console.log( 'singleton instance not found, creating new' );
            const inst = new constrFn( ...diValues );
            Object.defineProperty(
              constrFn,
              '[[instance]]',
              {
                configurable: false,
                enumerable: false,
                value: inst,
                writable: false
              }
            );
            return inst;
          }
        ],
        writable: false
      }
    );

    return constrFn;
  };
};
