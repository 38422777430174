import { ConstructorFn } from '../base/descriptors';

export interface FormFieldsConfig {
  [ fieldName: string ]: FormFieldConfig<string>;
}

export interface FormFieldConfig<T> {
  default: T;
  /**
   * Defaults to true
   * @type {boolean}
   */
  required?: boolean;
  validate?: ( value: T, model: BaseFormModel ) => boolean;
}

export const FormModel = ( function ( config: FormFieldsConfig ) {
  return ( function ( constrFn: ConstructorFn<BaseFormModel> ) {
    if ( typeof config !== 'object' ) { return; }

    return class extends constrFn {
      public config = config;

      constructor() {
        super();

        for ( const key in config ) {
          if ( !( key in config ) ) { continue; }

          const cfg = config[ key ];

          ( this as any )[ key ] = cfg.default;

          if ( cfg.required !== false ) {
            this.required.push( key );
          }
        }
      }
    } as ConstructorFn<BaseFormModel>;
  } );
} );

export abstract class BaseFormModel {
  protected config: FormFieldsConfig;
  protected required: Array<string> = [];

  constructor() {
    this.reset();
  }

  /**
   * Validates all required fields
   *
   * @returns {boolean}
   * @memberof BaseFormModel
   */
  public validate(): boolean {
    const reqVaidatedCount = this.required.filter(
      key => {
        const fieldCfg = this.config[ key ];
        const fieldVal = ( this as any )[ key ] as any;
        console.log( fieldCfg, fieldVal );
        return typeof fieldCfg.validate === 'function'
          ?
          fieldCfg.validate( fieldVal, this ) || console.log( `invalid field "${key}"` )
          :
          (
            typeof fieldVal === 'string'
            || console.log( `invalid field "${key}" type "${typeof fieldVal}"` )
          ) && (
            fieldVal.length > 0
            || console.log( `invalid field "${key}", length = 0` )
          );
      }
    ).length;
    return reqVaidatedCount === this.required.length;
  }

  public reset(): void {
    for ( const key in this.config ) {
      if ( !( key in this.config ) ) { continue; }
      ( this as any )[ key ] = this.config[ key ].default;
    }
  }

  public toFormData(): FormData {
    const fdata = new FormData();

    const keys = Object.keys( this );
    const values = keys.map( k => ( this as any )[ k ] );

    for ( let i = 0; i < keys.length; ++i ) {
      fdata.append( keys[ i ], values[ i ] );
    }

    return fdata;
  }

  public toJson(): string {
    const obj: { [ key: string ]: any; } = {};

    const keys = Object.keys( this ).filter(
      k => k !== 'required' && k !== 'config'
    );
    const values = keys.map( k => ( this as any )[ k ] );

    for ( let i = 0; i < keys.length; ++i ) {
      obj[ keys[ i ] ] = values[ i ];
    }

    return JSON.stringify( obj );
  }
}
