import { Controller, BaseController } from './controller';
import { UrlHelper } from '../helpers/url.helper';

@Controller()
export class StandortController extends BaseController {
  private uri = 'standorte/';

  public updateUrl( el: JQLite, open: boolean, entryUri: string ): void {
    // console.log( el, open );
    let targetUrl = UrlHelper.baseUrl;
    if ( open ) {
      targetUrl += entryUri;
    } else {
      targetUrl += this.uri;
    }
    // console.log( 'new url:', targetUrl );
    window.history.pushState(
      undefined,
      document.title,
      targetUrl
    );
  }
}
