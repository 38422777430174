import { Metadata } from './metadata';

export function ChildElement( selector: string ) {
  // console.log( 'ChildElement decorator, ' + selector );
  return Metadata.property(
    'ChildElement',
    ( prevVal: HTMLElement | undefined, el: JQLite ): HTMLElement | undefined => {
      // console.log( 'ChildElement(...)', prevVal, el );
      if ( !el || el.length === 0 || !( el[ 0 ] instanceof HTMLElement ) ) {
        return undefined;
      }
      const foundEl = el[ 0 ].querySelector( selector );
      if ( foundEl instanceof HTMLElement ) {
        return foundEl;
      }
      console.warn( 'No child element found with selector ' + selector );
      return undefined;
    }
  );
}
