import { Directive, BaseDirective } from './directive';
import { ChildElement } from '../base/element.decorator';
import { __createBinding } from 'tslib';
import { UrlHelper } from '../helpers/url.helper';


@Directive( {
  restrict: 'A',
  scope: {
    onToggle: '&?akkordeon',
    closeOthersSelector: '@?'
  }
} )
export class AkkordeonDirective extends BaseDirective {
  @ChildElement( '.akkordeon-item-content' )
  private set contentBox( val: HTMLDivElement ) {
    // prevent bubbling from akkordeon content to akkordeon item of this directive
    // because akkordeon item has the registered click event handler toggle()
    val.addEventListener( 'click', ( e: Event ) => { if ( e ) { e.stopPropagation(); } } );
  }

  constructor(
    public el: JQLite // needed for @ChildElement dependency "el"
  ) {
    super();
    el.children('.akkordeon-item-title-container').on( 'click', this.toggle.bind( this ) );
  }

  private toggle(e: Event) {

    e.stopPropagation()

    const isOpening = !this.el.hasClass( 'open' );

    if (
      isOpening
      && typeof this.closeOthersSelector === 'string'
      && this.closeOthersSelector.length > 0
    ) {
      $( this.closeOthersSelector ).each(
        ( i, el ) => {
          el.classList.remove( 'open' );
        }
      );
    }

    this.el.toggleClass( 'open' );

    if ( typeof this.onToggle === 'function' ) {
      // call function
      // important: parameters are defined in a param map
      // see: https://docs.angularjs.org/api/ng/service/$compile#-scope-
      this.onToggle( { el: this.el, open: isOpening } );
    }
  }

}
