import { BaseFormModel, FormModel } from './base-form.model';

@FormModel( {
  lastname: { default: '' },
  firstname: { default: '' },
  phone: { default: '', required: false },
  email: { default: '' },
  comment: { default: '' }
} )
export class FeedbackFormModel extends BaseFormModel { }
