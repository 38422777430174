import { Directive, BaseDirective } from './directive';
import { OverlaysService } from '../services/overlays.service';

@Directive( {
  restrict: 'A',
  // scope: {
  //   overlay: '<'
  // }
} )
export class OverlayDirective extends BaseDirective {
  constructor( private el: JQLite, private overlays: OverlaysService ) {
    super();

    // add click event handler to close overlay
    el.on(
      'click',
      e => {
        // console.log( e, el, overlays );
        e.stopPropagation();
        overlays.hideOverlays();
      }
    );

    // prevent scrolling of document body
    // this is a hack to enable e.stopPropagation()
    // TODO @pj: Doesn't work in Mozilla Firefox yet...
    // Is this working in Safari (& iOS)?
    el.on(
      'DOMMouseScroll mousewheel',
      ( e: WheelEvent | Event ): any => {
        // e.stopPropagation();
        // console.log( 'overlay scroll', e );
        const delta = e.type === 'DOMMouseScroll'
          ? e.originalEvent.detail * -40
          : e.wheelDelta;
        const up = delta > 0;

        const height = el[ 0 ].clientHeight;
        const scrollTop = el[ 0 ].scrollTop;
        const scrollHeight = el[ 0 ].scrollHeight;

        // console.log( el[ 0 ], delta, up, height, scrollTop, scrollHeight );

        const prevent = (): boolean => {
          e.stopPropagation();
          e.preventDefault();
          e.returnValue = false;
          return false;
        };

        if ( up && delta > scrollTop ) {
          // console.log( 'prevented' );
          el[ 0 ].scrollTop = 0;
          return prevent();
        } else if ( !up && -delta > ( scrollHeight - height - scrollTop ) ) {
          el[ 0 ].scrollTop = scrollHeight;
          return prevent();
        }
        // console.log( 'not prevented' );
      }
    );
  }
}
