function addMissingTrailingSlash( url: string ): string {
  if ( url.length > 1 && url.charAt( url.length - 1 ) !== '/' ) {
    url += '/';
  }
  return url;
}

/**
 * Webroot URL for folder ./web/
 * including trailing slash
 */
export const BASEURL: string = addMissingTrailingSlash( ( window as any ).BASEURL ); // defined in ./templates/_base/main.twig
/**
 * Website home of current language
 * including trailing slash
 */
export const SITEURL: string = addMissingTrailingSlash( ( window as any ).SITEURL ); // defined in ./templates/_base/main.twig
