interface SliderConfigs {
  default: JQuerySlickOptions;
  [ key: string ]: JQuerySlickOptions;
}

export abstract class Slider {
  private static readonly BTN_PREV = '<div class="slick-prev"></div>';
  private static readonly BTN_NEXT = '<div class="slick-next"></div>';
  public static readonly SLIDER_CONFIGS: SliderConfigs = {
    default: {
      autoplay: false,
      infinite: true,
      arrows: true,
      prevArrow: Slider.BTN_PREV,
      nextArrow: Slider.BTN_NEXT,
      dots: false,
      respondTo: 'window',
      responsive: [
        // {
        //   breakpoint: 1201, // active at <= 1200px
        //   settings: {
        //     slidesToShow: 5
        //   }
        // }
      ]
    },
    'slider-home': {
      autoplay: true,
      infinite: false,
      arrows: false,
      dots: false,
      slidesToShow: 3,
      variableWidth: false,
      adaptiveHeight: false,
      respondTo: 'window',
      responsive: [
        {
          breakpoint: 1201, // active at <= 1200px
          settings: {
            slidesToShow: 2,
            arrows: false
          }
        },
        {
          breakpoint: 994, // active at <= 993px
          settings: {
            slidesToShow: 1,
            arrows: false
          }
        }
      ]
    },
    'slider-webcam': {
      autoplay: false,
      infinite: true,
      arrows: true,
      prevArrow: Slider.BTN_PREV,
      nextArrow: Slider.BTN_NEXT,
      dots: false,
      slidesToShow: 1,
    }
  };

  public static initSliders(): void {
    const sliders = $( '.slider' );
    $( '.el-0' ).addClass( 'selected' );
    sliders.each(
      ( i, el ) => {
        Slider.initSlider( $( el ) );
      }
    );
    $( '.webcamList' ).click( function () {
      const index = $( this ).data( 'log' );
      sliders.slick( 'slickGoTo', index );

    } );
    sliders.on( 'afterChange', function ( slick, currentSlide ) {
      const slideNow = currentSlide.currentSlide;
      const totalSlides = $( '.slick-slide:not(.slick-cloned)' ).length;
      for ( let i = 0; i <= totalSlides - 1; i++ ) {
        $( '.el-' + i ).removeClass( 'selected' );
      }
      $( '.el-' + slideNow ).addClass( 'selected' );
    } );
  }


  private static initSlider( el: JQuery ): void {
    const config = Slider.getConfig( el );
    el.slick( config );
  }

  public static getConfig( el: JQuery ): JQuerySlickOptions {
    const id = el.attr( 'id' );
    if (
      typeof id === 'string' && id.length > 0
      && id in Slider.SLIDER_CONFIGS
    ) {
      return Slider.SLIDER_CONFIGS[ id ];
    }
    return Slider.SLIDER_CONFIGS.default;
  }
}


