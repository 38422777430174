/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 */

/**
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { module } from 'angular';
import SmoothScroll from 'smooth-scroll';
import { BASEURL, SITEURL } from './cms.globals';
import { BodyController } from './controllers/body.controller';
import { FeedbackFormController } from './controllers/feedback-form.controller';
import { GroupVisitFormController } from './controllers/group-visit-form.controller';
import { NewsController } from './controllers/news.controller';
import { StandortController } from './controllers/standort.controller';
import { AkkordeonDirective } from './directives/akkordeon.directive';
import { OverlayDirective } from './directives/overlay.directive';
import { OverlaysService } from './services/overlays.service';
import { ScrollService } from './services/scroll.service';
import { Slider } from './slider';

// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// ----------------------------------------------------------------
// Other globals

let scroll: SmoothScroll;

// ----------------------------------------------------------------
// AngularJS app

const APP_NAME = 'afbn';
const app = module( APP_NAME, [] );

// services
app.factory( 'overlays', OverlaysService.factory );
app.factory( 'scroll', ScrollService.factory );

// directives
app.directive( 'overlay', OverlayDirective.factory );
app.directive( 'akkordeon', AkkordeonDirective.factory );

// controllers
app.controller( 'body', BodyController );
app.controller( 'news', NewsController );
app.controller( 'standort', StandortController );
app.controller( 'groupform', GroupVisitFormController );
app.controller( 'feedbackform', FeedbackFormController );

// ----------------------------------------------------------------
// Event handlers

function onDocumentReady() {
  // console.log( 'onDocumentReady' );

  Slider.initSliders();
}

function onWindowLoaded() {
  // console.log( 'onWindowLoaded' );

  // initialize smooth scroll
  // https://www.npmjs.com/package/smooth-scroll
  scroll = new SmoothScroll( 'a[href*="#"]' );

  // check for URL hash and animate scroll, if set
  scrollToHashIfSet();
  showIconScrollTop();
}

// register event handlers
$( onDocumentReady );
$( window ).on( 'load', onWindowLoaded );

// ----------------------------------------------------------------
// other crazy stuff

function showIconScrollTop(): void {
  const scrollPosition = window.pageYOffset;
  const icon = document.getElementsByClassName( 'scroll-top-icon' )[ 0 ] as HTMLDivElement;
  if ( scrollPosition > 450 ) {
    icon.style.right = '12px';
  } else {
    icon.style.right = '-70px';
  }
}


function scrollToHashIfSet(): void {
  let hash = window.location.hash;
  if ( typeof hash === 'string' && hash.length > 1 ) {
    // remove /^#/ from hash
    if ( hash.charAt( 0 ) === '#' ) {
      hash = hash.substr( 1 );
    }

    const targetEl = document.getElementById( hash );
    if ( targetEl && 'offsetTop' in targetEl ) {
      // https://www.npmjs.com/package/smooth-scroll#animatescroll
      scroll.animateScroll( targetEl );
    }
  }
}

// ----------------------------------------------------------------
// add global scroll actions

document.addEventListener( 'mousewheel', ( ) => { showIconScrollTop(); }, false );
document.addEventListener( 'DOMMouseScroll', ( ) => { showIconScrollTop(); }, false );
document.addEventListener( 'scroll', ( ) => { showIconScrollTop(); }, false );
