export type DescriptorFn = ( ...metaArgs: any[] ) => ( <T>( constrFn: ConstructorFn<T>, ...args: any[] ) => T );
export interface ConstructorFn<T> {
  new( ...args: any[] ): T;
}

// ---------------------------------------------------
// helper functions

export interface DynObj { [ key: string ]: any; }

/**
 * Assigns a class instance (object) to a target including
 * its methods.
 * It overwrites existing properties or methods
 *
 * @export
 * @param {DynObj} target
 * @param {DynObj} source
 * @returns {DynObj} target + all properties and methods from source
 */
export function assignComponentToScope( target: DynObj, source: DynObj ): DynObj {
  const descriptors: DynObj = Object.keys( source ).reduce(
    ( obj: DynObj, key: string ) => {
      obj[ key ] = Object.getOwnPropertyDescriptor( source, key );
      return obj;
    },
    {}
  );
  // by default, Object.assign copies enumerable Symbols too
  ( Object as any ).getOwnPropertySymbols( source ).forEach(
    ( sym: string ) => {
      const descriptor = Object.getOwnPropertyDescriptor( source, sym );
      if ( descriptor && descriptor.enumerable ) {
        descriptors[ sym ] = descriptor;
      }
    }
  );
  Object.defineProperties( target, descriptors );

  return target;
}
