import { Controller, BaseController } from './controller';
import { FeedbackFormModel } from '../models/feedback-form.model';

@Controller()
export class FeedbackFormController extends BaseController {
  public model = new FeedbackFormModel();
  public sending = false;
  public sent = false;

  constructor( private $http: angular.IHttpService ) { super(); }

  public onSubmit( e: Event ): void {
    const isValid = this.model.validate();
    if ( !isValid ) {
      if ( e ) { e.preventDefault(); }
      console.log( 'form model not valid' );
      return;
    }

    const data = this.model.toJson();

    console.log( 'sending form', data );
    this.sending = true;
    this.sent = false;

    // send form data to controller action
    this.$http.post(
      'actions/afbn/forms/send-feedback',
      data
    ).then(
      response => {
        console.log( response );
        this.sent = true;
      }
    ).catch(
      err => {
        this.sent = false;
        if ( 'data' in err ) {
          console.error( err.data );
        } else {
          console.error( err );
        }
      }
    ).finally(
      () => { this.sending = false; }
    );
  }
}
