import { Controller, BaseController } from './controller';
import { OverlaysService } from '../services/overlays.service';
import { ScrollService } from '../services/scroll.service';

@Controller()
export class BodyController extends BaseController {
  constructor( public overlays: OverlaysService, public scroll: ScrollService ) {
    super();
    // console.log( this );
  }
}
