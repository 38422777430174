import { BASEURL, SITEURL } from '../cms.globals';

export abstract class UrlHelper {
  public static readonly baseUrl = BASEURL;
  public static readonly siteUrl = SITEURL;
  public static getUrl(): string {
    return window.location.href;
  }
  public static getUrlFolder(): string {
    let url = UrlHelper.getUrl();
    if ( url.charAt( url.length - 1 ) !== '/' ) {
      url += '/';
    }
    return url;
  }
  public static getUri( url?: string ): string {
    if ( typeof url !== 'string' ) {
      url = UrlHelper.getUrl();
    }
    return UrlHelper.getUriPartAfter( url, UrlHelper.siteUrl );
  }
  public static getUriFolder( url?: string ): string {
    let uri = UrlHelper.getUri( url );
    if ( uri.charAt( uri.length - 1 ) !== '/' ) {
      uri += '/';
    }
    return uri;
  }
  public static getUriPartAfter( url: string, searchPart: string ): string {
    const idx = url.indexOf( searchPart );
    console.log( 'url=' + url, 'searchPart=' + searchPart, 'idx=' + idx.toString() );
    if ( idx >= 0 ) {
      return UrlHelper.getCleanUri( url.substr( idx + searchPart.length ) );
    }
    return '';
  }

  public static getCleanUri( uri: string ): string {
    if ( uri.charAt( 0 ) === '/' ) {
      uri = uri.substr( 1 );
    }
    return uri;
  }
}
