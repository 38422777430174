import { EOverlay } from '../controllers/EOverlay';
import { Injectable, BaseInjectable } from '../base/injectable';

interface IOverlay {
  handle: EOverlay | null;
}

@Injectable()
export class OverlaysService extends BaseInjectable {
  private overlay: IOverlay = {
    handle: null
    // handle: EOverlay.navMain
  };

  constructor(
    private $rootScope: Array<angular.IRootScopeService>
  ) { super(); }

  public hasOverlay(): boolean {
    return typeof this.overlay.handle === 'string'
      && this.overlay.handle in EOverlay;
  }

  public isActive( handle: EOverlay | null ): boolean {
    return this.overlay.handle === handle;
  }

  public showOverlay( handle: EOverlay | null ): void {
    if ( typeof handle === 'string' && handle in EOverlay ) {
      this.overlay.handle = handle;
      console.log( 'Overlay set to:', this.overlay );
    } else {
      console.warn( 'Overlay not found', handle );
      this.hideOverlays();
    }
  }

  public hideOverlays(): void {
    this.overlay.handle = null;
  }

  public toggleOverlay( handle: EOverlay | null ): void {
    if ( this.hasOverlay() ) {
      this.hideOverlays();
    } else {
      this.showOverlay( handle );
    }
  }
}
