import { ConstructorFn, assignComponentToScope } from '../base/descriptors';
import { injector } from 'angular';
import { Metadata, IMetadata } from '../base/metadata';

// helper to get DI tokens
const $injector = injector();

interface BaseDirective extends ConstructorFn<BaseDirective>, angular.IScope, IMetadata {
  factory(): angular.IDirective;
}
export const BaseDirective: BaseDirective = ( function () {
  // console.log( 'BaseDirective constructor called' );
  // console.log( this );
  // console.log( 'initializing meta properties' );
  // Metadata.initProperties( this as IMetadata );
} ) as any as BaseDirective;

interface DirectiveOptions { // only allow a subset of angular.IDirective
  restrict: 'A' | 'C' | 'AC';
  scope?: boolean | { [ boundProperty: string ]: string };
}
interface DirectiveDescriptorFn {
  ( options: DirectiveOptions ): ( <T extends BaseDirective>( constrFn: T ) => typeof T );
}

/**
 * A custom-made AngularJS Directive decorator and base class
 *
 * The decorator merges all methods and properties of the directive into the $scope variable.
 *
 * The base class is just for type hinting so you can access $scope properties
 * and methods with "this."
 *
 * @author BINARY one, pj
 */
export const Directive: DirectiveDescriptorFn = ( options: DirectiveOptions ) => {
  const nonDiArgs = [ 'el', 'attrs' ];

  return <T extends BaseDirective>( constrFn: T ) => {
    // console.log( '@Directive descriptor called', options );
    // console.log( constrFn );
    // console.log( constrFn.prototype );

    // get dependencies to inject
    const diArgs = $injector.annotate( constrFn );

    // exclude dependencies which are only in linkFn
    const ngDiArgs = diArgs.filter(
      arg => -1 === nonDiArgs.indexOf( arg )
    );

    // console.log( 'ngDi:', ngDiArgs );

    // add static method factory()
    Object.defineProperty(
      constrFn,
      'factory',
      {
        configurable: false,
        enumerable: false,
        value: [
          ...ngDiArgs,
          ( ...ngDiValues: Array<any> ) => {
            const directiveOptions: angular.IDirective = options;

            // directive link function
            directiveOptions.link = ( scope, el, attrs, ctrl, transcludeFn ) => {
              // console.log( 'linkFn' );

              assignComponentToScope( scope, constrFn.prototype );
              // console.log( scope );
              const diValues = new Array( diArgs.length );
              let i = 0;
              let ngI = 0;
              for ( i = 0; i < diArgs.length; ++i ) {
                if ( diArgs[ i ] === ngDiArgs[ ngI ] ) {
                  // dependencies from directive injector
                  diValues[ i ] = ngDiValues[ ngI ];
                  ++ngI;
                } else {
                  // inner dependencies from linkFn ('el', 'attr', ...)
                  switch ( diArgs[ i ] ) {
                    case 'el': diValues[ i ] = el; break;
                    case 'attrs': diValues[ i ] = attrs; break;
                    default:
                      diValues[ i ] = undefined;
                  }
                }
              }
              // console.log( 'diArgs:', diArgs, ngDiArgs );
              // console.log( 'diValues:', diValues, ngDiValues );

              // console.log( 'calling constrFn' );
              constrFn.call( scope, ...diValues );

              if ( Metadata.PROPERTY_KEY in constrFn.prototype ) {
                // console.log( 'initializing meta properties' );
                Metadata.initProperties(
                  scope as T & IMetadata,
                  constrFn.prototype[ Metadata.PROPERTY_KEY ]
                );
              }
              // console.log( 'linkFn meta', Reflect.getMetadataKeys( constrFn.prototype ) )
              // Object.assign( scope, inst );
              // console.log( 'linkFn', scope );
            };
            const scopeType = typeof directiveOptions.scope;
            if ( scopeType === 'undefined' || directiveOptions.scope === null ) {
              directiveOptions.scope = true;
            }

            return directiveOptions;
          }
        ],
        writable: false
      }
    );

    return constrFn;
  };
};
