import { ConstructorFn, assignComponentToScope } from '../base/descriptors';
import { injector } from 'angular';

// helper to get DI tokens
const $injector = injector();

interface BaseController extends ConstructorFn<BaseController>, angular.IScope { }
export const BaseController: BaseController = ( () => {
  // console.log( 'BaseController constructor called' );
} ) as any as BaseController;

interface ControllerDescriptorFn {
  ( ...metaArgs: any[] ):
    <T extends BaseController>( constrFn: T, ...args: any[] ) => typeof T; // hack, actual type is angular.Injectable<T>
}

/**
 * A custom-made AngularJS Controller decorator and base class
 *
 * The decorator merges all methods and properties of the controller into the $scope variable.
 *
 * The base class is just for type hinting so you can access $scope properties
 * and methods with "this."
 *
 * @author BINARY one, pj
 */
export const Controller: ControllerDescriptorFn = ( ...metaArgs: any[] ) => {
  return <T extends BaseController>( constrFn: T, ...args: any[] ) => {
    // console.log( '@Controller descriptor called', constrFn, args );

    const depInjectorArgs = $injector.annotate( constrFn );
    const injectorArgs: angular.Injectable<T> = [ ...depInjectorArgs ];

    // add actual controller function
    injectorArgs.push(
      ( ( $scope: angular.IScope, ...injectedDeps: Array<any> ) => {
        // console.log( 'AngularJS injector called' );
        // assign Controller methods to $scope
        assignComponentToScope( $scope, constrFn.prototype );
        // console.log( 'controller prototype copied to $scope' );

        // return the Controller constructor,
        // but use the created AngularJS $scope as "this"
        constrFn.call( $scope, ...injectedDeps );
      } ) as any as T
    );

    // console.log( [
    //   '$scope',
    //   ...injectorArgs
    // ] );

    // returns an AngularJS injector array
    return [
      '$scope',
      ...injectorArgs
    ] as any as typeof T; // hack, actual type is angular.Injectable<T>
  };
};
