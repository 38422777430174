import { injector } from 'angular';

// helper to get DI tokens
const $injector = injector();

interface MetaFn {
  ( prevValue: any, ...args: Array<any> ): any;
}

export interface IMetadata {
  '[[Metadata]]': IMetadataMap;
  [ key: string ]: any;
}
interface IMetadataMap {
  [ prop: string ]: IMetadataPropMap;
}
interface IMetadataPropMap {
  [ metaKey: string ]: MetaFn;
}

interface PropertyDecoratorMetaHandlerFn {
  ( constrFn: IMetadata, property: string ): void;
}

export class Metadata {
  public static readonly PROPERTY_KEY = '[[Metadata]]';

  public static property( symbolKey: string, metaFn: MetaFn ): PropertyDecoratorMetaHandlerFn {
    return ( constrFn: IMetadata, property: string ) => {
      const hasMetaProp = typeof Object.getOwnPropertyDescriptor( constrFn, Metadata.PROPERTY_KEY ) !== 'undefined';
      if ( !hasMetaProp ) {
        Object.defineProperty( constrFn, Metadata.PROPERTY_KEY, {
          configurable: false,
          enumerable: false,
          value: {},
          writable: false
        } );
      }
      // console.log( 'constrFn', Object.keys( constrFn ), Object.getOwnPropertyDescriptors( constrFn ) );
      if ( !( property in constrFn[ Metadata.PROPERTY_KEY ] ) ) {
        constrFn[ Metadata.PROPERTY_KEY ][ property ] = {};
      }
      constrFn[ Metadata.PROPERTY_KEY ][ property ][ symbolKey ] = metaFn;
    }
  }

  public static initProperties( target: IMetadata, metadata?: IMetadataMap ): void {
    // if ( !Object.getOwnPropertyDescriptor( target, Metadata.PROPERTY_KEY ) ) {
    //   console.log( 'Metadata not found on target', target );
    //   // TODO: find metadata in base classes and replace target ref
    //   return;
    // }
    if ( typeof metadata === 'undefined' ) {
      metadata = target[ Metadata.PROPERTY_KEY ];
    }
    // console.log( 'Metadata found:', metadata );
    const metaProps = Object.keys( metadata );
    for ( const prop of metaProps ) {
      const propMetaObj = metadata[ prop ];
      for ( const symbolKey in propMetaObj ) {
        if ( !( symbolKey in propMetaObj ) ) { continue; }
        // console.log( 'debug: ' + symbolKey, target, metadata );
        const metaFn = propMetaObj[ symbolKey ];
        const diArgs = $injector.annotate( metaFn ).slice( 1 ); // skip first arg as it is "prevValue"
        const diArgsValues = new Array( diArgs.length );
        const prevValue = target[ prop ];

        for ( let i = 0; i < diArgs.length; ++i ) {
          const arg = diArgs[ i ];
          if ( arg in target ) {
            diArgsValues[ i ] = target[ arg ];
          } else {
            console.warn(
              'Decorator @' + symbolKey + ' Dependency '
              + '"' + arg + '" not found in Class '
              + '"' + target.__proto__.constructor.name + '"'
            );
            diArgsValues[ i ] = undefined;
          }
        }
        // console.log( '@' + symbolKey + '(...) ' + prop + ' diArgs:', diArgs, diArgsValues );
        // console.log( 'prevValue:', prevValue );
        // call meta function with previous property value and dependencies
        const val = metaFn( prevValue, ...diArgsValues );
        // console.log( 'metaFn(..) => val:', metaFn, val );
        target[ prop ] = val;
      }
    }
  }
}
