import { Injectable, BaseInjectable } from '../base/injectable';
import * as SmoothScroll from 'smooth-scroll';

@Injectable()
export class ScrollService extends BaseInjectable {
  private smoothScroll: SmoothScroll;

  public to( target: number | Element | null, options?: SmoothScroll.Options ): void {
    if ( !this.smoothScroll ) {
      this.smoothScroll = new SmoothScroll();
    }

    options = options || {};
    options.updateURL = false;
    options.popstate = false;

    this.smoothScroll.animateScroll( target, undefined, options );
  }
}
