import { Controller, BaseController } from './controller';
import { UrlHelper } from '../helpers/url.helper';

@Controller()
export class NewsController extends BaseController {
  private uri = 'news/';

  constructor() {
    super();

    window.scrollTo(
      0,
      (document.getElementsByClassName('akkordeon-item open')[0]?.getBoundingClientRect().top ? (document.getElementsByClassName('akkordeon-item open')[0]?.getBoundingClientRect().top - 150) : 0)
    );

    const currentUrl = UrlHelper.getUrl();
    const targetUrl = currentUrl.split('?')[0];

    window.history.pushState(
      undefined,
      document.title,
      targetUrl
    );
  }

  public updateUrl( el: JQLite, open: boolean, entryUri: string ): void {
    // console.log( el, open );
    let targetUrl = UrlHelper.baseUrl;
    if ( open ) {
      targetUrl += entryUri;
    } else {
      targetUrl += this.uri;
    }
    // console.log( 'new url:', targetUrl );
    window.history.replaceState(
      undefined,
      document.title,
      targetUrl
    );
  }
}
